export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    width: 2500,
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    width: 6000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'itemCode',
    label: 'field.itemCode',
    width: 3500,
  },
  {
    key: 'itemName',
    label: 'field.item',
    localization: true,
    width: 6000,
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'image',
    width: 10000,
    exportKey: 'imageId',
    isImage: true,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    type: 'date',
    width: 4000,
  },
  {
    key: 'provinceName',
    label: 'field.province',
    localization: true,
    width: 4000,
  },
  {
    key: 'districtName',
    label: 'field.district',
    localization: true,
    width: 4000,
  },
  {
    key: 'communeName',
    label: 'field.commune',
    localization: true,
    width: 4000,
  },
  {
    key: 'landmark',
    label: 'field.landmark',
    width: 6000,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
    width: 4000,
  },
  {
    key: 'longitude',
    label: 'field.longitude',
    width: 4000,
  },
  {
    key: 'type',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
    options: ["", "field.callPlan", "field.public"]
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
    options: ["field.notVisit", "field.visiting", "field.visited"]
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
    options: ["field.offRoute", "field.onRoute"]
  },
];
