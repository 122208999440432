export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'regionCode',
    label: 'field.region',
    hideToggle: true,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    hideToggle: true,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    subKey: 'outletCode',
    hideToggle: true,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'itemName',
    label: 'field.item',
    localization: true,
    subKey: 'itemCode',
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'image',
    hideToggle: true,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'provinceName',
    label: 'field.province',
    localization: true,
  },
  {
    key: 'districtName',
    label: 'field.district',
    localization: true,
  },
  {
    key: 'communeName',
    label: 'field.commune',
    localization: true,
  },
  {
    key: 'landmark',
    label: 'field.landmark',
  },
  {
    key: 'latitude',
    label: 'field.latitude',
  },
  {
    key: 'longitude',
    label: 'field.longitude',
  },
  {
    key: 'type',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
];
